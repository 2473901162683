import React, { useEffect, useState } from 'react'

import Link from 'next/link'
import { Trans } from '@lingui/macro'
import { setupI18n } from '@lingui/core'
import { randomString } from '../../utils/random'
import { Bar, BarItem, ButtonPrimary, Container, Grid, GridCol, Icon, Section } from '../../ui-kit'
import { BASE_URLS, LINKS, SCOPES, URLS } from '../../../config/constants'
import sk from '../../../locales/sk/messages'
import { useScript } from '../../utils/hooks/useScript'

type infolinkType = {
  text: string
  url: string
  hideClient?: boolean
}

interface infolinksInterface {
  [key: number]: infolinkType[]
}

export const Footer: React.SFC<{ userRole: SCOPES }> = ({ userRole }) => {
  // Fixme: This is hotfix / not working translates before login??
  const lang = 'sk'
  const [catalogs, setCatalogs] = useState({ sk } as any)
  const [cacheInvalidateToken] = useState(() => randomString())

  const [loaded] = useScript(
    `https://${BASE_URLS.cms}/translations/translation_web_${lang}.js?${cacheInvalidateToken}`
  )

  useEffect(() => {
    if (loaded) {
      const translations = (window as any)[`__trans_${lang}`]
      setCatalogs({ [lang]: translations })
    }
  }, [loaded, lang])

  if (userRole == null) {
    // unauthorized user
    return null
  }

  const i18n = setupI18n({ language: 'sk', catalogs })

  const places = [
    {
      title: i18n.t`Sídlo`,
      items: [i18n.t`Tomášikova 64A`, i18n.t`831 04 Bratislava – mestská časť Nové Mesto`],
      link: {
        text: i18n.t`Pozrieť na mape`,
        url: LINKS.contact.map,
      },
    },
  ]

  const social = [
    {
      title: 'facebook',
      icon: 'facebook',
      url: LINKS.contact.social.facebook,
    },
    {
      title: 'youtube',
      icon: 'youtube',
      url: LINKS.contact.social.youtube,
    },
    {
      title: 'linkedin',
      icon: 'linkedin',
      url: LINKS.contact.social.linkedin,
    },
    { title: 'up', icon: 'up', url: LINKS.contact.social.coop },
  ]

  const FOOTER_LINKS = [
    {
      text: i18n.t`Pravidlá Klubu Up`,
      url: LINKS.club,
    },
    {
      text: i18n.t`Tlačivá a programy`,
      url: LINKS.docs,
      hideClient: true,
    },
    {
      text: i18n.t`Všeobecné obchodné podmienky`,
      url: LINKS.docs,
    },
    {
      text: i18n.t`Reklamačný poriadok`,
      url: LINKS.complaints,
    },
    {
      text: i18n.t`Ochrana osobných údajov`,
      url: LINKS.gdpr,
    },
  ]

  const infolinks: infolinksInterface = {
    [SCOPES.Client]: FOOTER_LINKS,
    [SCOPES.Affiliate]: FOOTER_LINKS,
    [SCOPES.Holder]: FOOTER_LINKS,
    [SCOPES.Public]: FOOTER_LINKS,
    [SCOPES.User]: FOOTER_LINKS,
  }

  return (
    <Section color="light-grey" role="contentinfo" className="border-top border-light-grey">
      <Container>
        <Grid className="mb-xxlarge">
          <GridCol size={{ l: 5 }} className="mb">
            <h2 className="h5 mb">
              <Trans>O spoločnosti Up Slovensko, s. r. o.</Trans>
            </h2>
            <Grid>
              {places.map(place => (
                <GridCol size={{ s: 8 }} className="mb-large text-xlarge" key={place.title}>
                  <h3 className="text-default text-bold mb">{place.title}</h3>
                  <ul className="list--unstyled mb">
                    {place.items.map(item => (
                      <li key={item} className="text-small">
                        {item}
                      </li>
                    ))}
                  </ul>
                  <a
                    href={place.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-black text-small">
                    {place.link.text}
                  </a>
                </GridCol>
              ))}
              <GridCol>
                <p className="text-bold">
                  <Trans>Nájdete nás aj na</Trans>
                </p>
                <Bar space="small">
                  {social.map(item => (
                    <BarItem key={item.title}>
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={`/static/img/social-${item.icon}.png`} alt={item.title} />
                      </a>
                    </BarItem>
                  ))}
                </Bar>
              </GridCol>
            </Grid>
          </GridCol>
          <GridCol size={{ l: 4 }} className="border-left border-light-grey mb-xxlarge">
            <hr className="hide-l border-light-grey" />
            <h2 className="h5 mb">
              <Trans>Služby zákazníkom</Trans>
            </h2>
            <p>
              <span className="text-bold">
                <Trans>Kontaktujte nás</Trans>
              </span>
              <br />
              <span className="text-small text-color-grey">{i18n.t`v pracovných dňoch 8:00-16:30`}</span>
            </p>
            <div>
              <div className="text-bold mb-small">
                <Trans>Infolinky pre klientov:</Trans>
              </div>
              <Grid className="mb-small">
                <GridCol size={{ l: 6 }}>
                  <Trans>Karta Up Déjeuner</Trans>
                  <ul className="list--unstyled mb flex flex-col">
                    <li>
                      <a
                        href={`tel:${i18n.t`02/ 32 55 35 45`}`}
                        className="link text-color-default text-small">
                        {i18n.t`02/ 32 55 35 45`}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${i18n.t`info@up-dejeuner.sk `}`}
                        className="link-black text-small">
                        {i18n.t`info@up-dejeuner.sk `}
                      </a>
                    </li>
                  </ul>
                </GridCol>

                <GridCol size={{ l: 6 }}>
                  <Trans>Palivová karta Shell</Trans>
                  <ul className="list--unstyled mb flex flex-col">
                    <li>
                      <a
                        href={`tel:${i18n.t`02/ 32 55 35 81`}`}
                        className="link text-color-default text-small"
                        style={{ fontSize: '0.95rem' }}>
                        {i18n.t`02/ 32 55 35 81`}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${i18n.t`palivo@up-dejeuner.sk`}`}
                        className="link-black text-small"
                        style={{ fontSize: '0.95rem' }}>
                        {i18n.t`palivo@up-dejeuner.sk`}
                      </a>
                    </li>
                  </ul>
                </GridCol>
              </Grid>

              <div className="text-bold mb-small">
                <Trans>Infolinky pre používateľov:</Trans>
              </div>
              <Grid className="mb-large">
                <GridCol size={{ l: 6 }}>
                  <Trans>Karta Up Déjeuner</Trans>
                  <ul className="list--unstyled mb flex flex-col">
                    <li>
                      <a
                        href={`tel:${i18n.t`02/ 32 55 35 65`}`}
                        className="link text-color-default text-small">
                        {i18n.t`02/ 32 55 35 65`}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${i18n.t`info@up-dejeuner.sk `}`}
                        className="link-black text-small">
                        {i18n.t`info@up-dejeuner.sk `}
                      </a>
                    </li>
                  </ul>
                </GridCol>

                <GridCol size={{ l: 6 }}>
                  <Trans>Palivová karta Shell</Trans>
                  <ul className="list--unstyled mb flex flex-col">
                    <li>
                      <a
                        href={`tel:${i18n.t`02/ 32 55 35 81`}`}
                        className="link text-color-default text-small">
                        {i18n.t`02/ 32 55 35 81`}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${i18n.t`palivo@up-dejeuner.sk`}`}
                        className="link-black text-small">
                        {i18n.t`palivo@up-dejeuner.sk`}
                      </a>
                    </li>
                  </ul>
                </GridCol>
              </Grid>
            </div>
            <Link href={URLS.contact({})}>
              <a>
                <ButtonPrimary size="small" space="condensed">
                  <Icon name="mail" size="medium" className="icon--left" />
                  <Trans>Napíšte nám</Trans>
                </ButtonPrimary>
              </a>
            </Link>
          </GridCol>
          <GridCol size={{ l: 3 }} className="border-left border-light-grey mb-xxlarge">
            <hr className="hide-l border-light-grey" />
            <h2 className="h5">
              <Trans>Užitočné informácie</Trans>
            </h2>

            <ul className="list--unstyled mb-xlarge">
              <li className="text-small">
                <a href="/supported-browsers" className="link text-color-default">
                  <Trans>Podporované webové prehliadače</Trans>
                </a>
              </li>
              {infolinks[userRole].map((link, index) => (
                <li key={index.toString()} className="text-small">
                  <a href={link.url} className="link text-color-default">
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
            <p className="text-bold">
              <Trans>Stiahnite si našu mobilnú aplikáciu</Trans>
            </p>
            <Bar className="mb-none">
              <BarItem>
                <a
                  href={i18n.t`https://play.google.com/store/apps/details?id=sk.upsk.slovensko`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="/static/illustrations/playstore.svg" alt="Google Play" />
                </a>
              </BarItem>
              <BarItem>
                <a
                  href={i18n.t`https://apps.apple.com/sk/app/zona-up/id1458326746?l=sk`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src="/static/illustrations/appstore.svg" alt="App Store" />
                </a>
              </BarItem>
            </Bar>
          </GridCol>
        </Grid>
        <Bar align="middle" className="align-items-center">
          <BarItem>
            <img src="/static/img/logo/logo-small.svg" alt="Up" width="64" height="63" />
          </BarItem>
          <BarItem>
            <h4 className="text-color-theme-500">
              <Trans>Spríjemní každý deň</Trans>
            </h4>
          </BarItem>
        </Bar>
      </Container>
    </Section>
  )
}
