import React, { useState } from 'react'
import css from 'styled-jsx/css'
import { Trans } from '@lingui/macro'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { Notice, UserMessage } from '@odiupsk/up-api-client'
import {
  ButtonDropdown,
  Icon,
  Tabs,
  TabPanel,
  TabLink,
  List,
  ListItem,
  Grid,
  GridCol,
  ButtonPrimary,
  ButtonLayout,
} from '../../ui-kit'
import { getCurrentScopeUrl } from '../../utils/routing'
import { appStore } from '../../AppStore'
import { i18n } from '../../utils/intl'
import { upAxios } from '../../../config'
import { useDidMount } from '../../utils/hooks/useDidMount'
import { formatDate } from '../../utils/format'
import { NotificationStore } from './stores/NotificationStore'
import { MessageStore } from './stores/MessageStore'
import { Auth } from '../auth/Auth'

const getStyles = (numberOfUnread: number) => css.resolve`
  :after {
    content: ${numberOfUnread ? `"${numberOfUnread}"` : 'none'} !important;
  }
`

export const Notifications = observer(() => {
  const [notificationStore] = useState(() => new NotificationStore())
  const [messageStore] = useState(() => new MessageStore())
  const [initialTab, setInitialTab] = useState(0)
  const numberOfUnread = messageStore.numberOfUnread + notificationStore.numberOfUnread

  useDidMount(() => {
    if (!appStore.currentUser) return
    if (Auth.forceChangeEmail(Auth.getAccessToken()!)) return
    fetchNotices()

    const interval = setInterval(() => {
      notificationStore.refreshNotices(false)
      messageStore.refreshMessages(false)
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  })

  const fetchNotices = async () => {
    await notificationStore.fetchNotices({
      page: 1,
      per_page: notificationStore.pagination.perPage,
    })
    await messageStore.fetchMessages({ page: 1, per_page: messageStore.pagination.perPage })
    setInitialTab(messageStore.numberOfUnread === 0 && notificationStore.numberOfUnread > 0 ? 1 : 0)
  }

  const { styles, className } = getStyles(numberOfUnread)

  return (
    <>
      <ButtonDropdown
        key="notifications-action"
        space="condensed"
        type="link-secondary"
        className={cx({ 'has-notification': numberOfUnread }, className, 'notifications-bell')}
        iconToggle="notifications"
        content={<Icon size="medium" name="notifications" color="white" />}
        dropdownWidth="wider"
        dropdownArrow
        dropdownInteractive
        dropdownClassName="notifications-and-messages"
        onClick={() => {
          notificationStore.refreshNotices(true)
          messageStore.refreshMessages(true)
        }}>
        <div className="notifications-and-messages__wrapper up-scrollbar">
          <Tabs
            activeTabIndex={initialTab}
            onChange={async index => {
              if (index === 1 && notificationStore.numberOfUnread > 0) {
                await notificationStore.acknowledgeAll()
              }
            }}>
            <TabPanel
              tab={
                <TabLink href="#bell-messages" id="bell-messages" isActive>
                  <div
                    className="flex-row"
                    style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                    <Icon style={{ marginRight: 4 }} name="mail" size="medium" />
                    <Trans>Správy</Trans>
                    <p style={{ fontSize: 12, marginLeft: 2 }} className="text-color-grey-600">
                      {messageStore.numberOfUnread}
                    </p>
                  </div>
                </TabLink>
              }>
              <div className="mb-smallish" />
              {messageStore.messages.length ? (
                <>
                  <List clean className="mb-none" space="condensed-v">
                    {messageStore.messages.map((message, index) => (
                      <MessageRow
                        length={messageStore.messages.length}
                        message={message}
                        index={index}
                        key={message.id}
                      />
                    ))}
                  </List>

                  {messageStore.pagination.canLoadMore && (
                    <ButtonLayout direction="horizontal" className="align-items-center">
                      <ButtonPrimary
                        onClick={() => {
                          messageStore.fetchMessages({
                            page: messageStore.pagination.page + 1,
                            per_page: messageStore.pagination.perPage,
                          })
                        }}
                        // isLoading={messageStore.loading}
                        isDisabled={messageStore.loading}>
                        <Trans>Nahrať ďalšie správy</Trans>
                      </ButtonPrimary>
                    </ButtonLayout>
                  )}
                </>
              ) : (
                <EmptyMessage text={i18n.t`Nemáte žiadne správy`} />
              )}
            </TabPanel>
            <TabPanel
              tab={
                <TabLink href="#bell-notifications" id="bell-notifications">
                  <div
                    className="flex-row"
                    style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                    <Icon style={{ marginRight: 4 }} name="notifications" size="medium" />
                    <Trans>Notifikácie</Trans>
                    <p style={{ fontSize: 12, marginLeft: 2 }} className="text-color-grey-600">
                      {notificationStore.numberOfUnread}
                    </p>
                  </div>
                </TabLink>
              }>
              <div className="mb-smallish" />
              {notificationStore.notifications.length ? (
                <>
                  <List clean className="mb-none" space="condensed-v">
                    {notificationStore.notifications.map((notification: Notice, index) => (
                      <NotificationRow
                        notification={notification}
                        index={index}
                        key={notification.id}
                        length={notificationStore.notifications.length}
                      />
                    ))}
                  </List>

                  {notificationStore.pagination.canLoadMore && (
                    <ButtonLayout direction="horizontal" className="align-items-center">
                      <ButtonPrimary
                        onClick={() => {
                          notificationStore.fetchNotices({
                            page: notificationStore.pagination.page + 1,
                            per_page: notificationStore.pagination.perPage,
                          })
                        }}
                        // isLoading={notificationStore.loading}
                        isDisabled={notificationStore.loading}>
                        <Trans>Nahrať ďalšie notifikácie</Trans>
                      </ButtonPrimary>
                    </ButtonLayout>
                  )}
                </>
              ) : (
                <EmptyMessage text={i18n.t`Nemáte žiadne notifikácie`} />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </ButtonDropdown>
      {styles}
    </>
  )
})

Notifications.displayName = 'Notifications'

const MessageRow: React.FC<{
  message: UserMessage
  index: number
  length: number
}> = ({ message, index, length }) => {
  const acknowledgeAndProceed = () => {
    const redirect = `${getCurrentScopeUrl()}/messages/d?id=${message.message_id}`
    if (!appStore.currentUser) return
    upAxios
      .post(`/messages/${message.id}/acknowledgment`)
      .then(() => {
        window.location.href = redirect
      })
      .catch(err => {
        console.log(err)
        window.location.href = redirect
      })
  }
  return (
    <>
      <ListItem
        className="mb-smallish"
        clickable
        onClick={acknowledgeAndProceed}
        isSelected={!message.is_acknowledged}>
        {message.title && (
          <div
            className={cx('text-small', 'text-bold', 'mb-smallish', {
              'text-color-theme-500': !message.is_acknowledged,
            })}>
            {message.title.sk}
            <Icon name="chevron-right" size="small" style={{ transform: 'translateY(3px)' }} />
          </div>
        )}
        {message.perex && (
          <div className="text-small text-color-grey-700 mb-smallish">{message.perex.sk}</div>
        )}
        <div className="text-small text-color-grey-700">{formatDate(message.published_at!)}</div>
      </ListItem>
      {index + 1 < length && <div className="border-top mb-smallish" />}
    </>
  )
}

const NotificationRow: React.FC<{
  notification: Notice
  index: number
  length: number
}> = ({ notification, index, length }) => {
  return (
    <>
      <ListItem
        className="mb-smallish"
        clickable
        isSelected={!notification.is_acknowledged}
        style={{ pointerEvents: 'none' }}>
        <Grid>
          <GridCol size={1}>
            <Icon name="card-black" size="medium" style={{ transform: 'translateY(7px)' }} />
          </GridCol>
          <GridCol size={10}>
            {notification.title && (
              <div
                className={cx('text-small', 'text-bold', {
                  'text-color-theme-500': !notification.is_acknowledged,
                })}>
                {notification.title.sk}
              </div>
            )}

            {notification.text && (
              <div
                className={cx(
                  'text-small',
                  notification.is_acknowledged ? 'text-color-grey-300' : 'text-color-theme-500'
                )}>
                {notification.text.sk}
              </div>
            )}

            <div
              className={cx(
                'text-small',
                notification.is_acknowledged ? 'text-color-grey-300' : 'text-color-theme-500'
              )}>
              {formatDate(notification.published_at!)}
            </div>
          </GridCol>
        </Grid>
      </ListItem>
      {index + 1 < length && <div className="border-top mb-smallish" />}
    </>
  )
}

interface EmptyMessageProps {
  text: string
}
const EmptyMessage: React.FC<EmptyMessageProps> = ({ text }) => {
  return (
    <p className="text-center" style={{ marginTop: '20px' }}>
      {text}
    </p>
  )
}
