import { observable, computed, reaction } from 'mobx'
import { Pagination } from '@odiupsk/up-api-client'

/**
 * store used to handle pagination logic
 */
export class PaginationStore {
  @observable perPage = 10

  @observable page = 1

  @observable objectCount: number = 0

  constructor(source: Partial<Pick<PaginationStore, 'objectCount' | 'page' | 'perPage'>>) {
    Object.assign(this, source)
  }

  @computed get numPages() {
    return Math.ceil(this.objectCount / this.perPage)
  }

  @computed get canLoadMore() {
    return this.page < this.numPages
  }

  dispose = reaction(
    () => this.perPage,
    () => {
      this.page = 1
    }
  )

  @computed get params() {
    const { page, perPage: per_page } = this
    return { page, per_page }
  }

  setFromPagination(arg: Pagination) {
    this.perPage = arg.per_page ?? 10
    this.page = arg.page ?? 1
    this.objectCount = arg.object_count ?? 0
  }
}
