import React, { useEffect, useState } from 'react'
import { browserName, browserVersion } from 'react-device-detect'
import { I18nProvider } from '@lingui/react'
import NProgress from 'nprogress'
import ReactGA from 'react-ga'
import Router from 'next/router'
import { observer } from 'mobx-react-lite'
import { BASE_URLS, LINKS, SupportedBroswerMap } from '../../../config/constants'
import { BusProvider, globalBus } from '../../utils/hooks/useGlobalBus'
import { Sprite, Sprite2 } from '../../../../static/sprite'

import { ErrorBoundary } from '../../utils/ErrorBoundary'
import { Footer } from './Footer'
import { GOOGLE_ANALYTICS_KEY } from '../../../config'
import { Header } from './Header'
import { ModalUnsupportedBrowser } from '../supported-browsers/ModalUnsupportedBrowser'
import { appStore } from '../../AppStore'
// import { canUseDOM } from '../../utils/hoc'
import { i18n } from '../../utils/intl'
import { randomString } from '../../utils/random'
// sk catalog is default
import sk from '../../../locales/sk/messages'
import { useDialog } from '../../utils/hooks/useDialog'
import { useScript } from '../../utils/hooks/useScript'

// import CookieConsentDialog from '@brackets/cookie-consent-dialog' 99 problems

interface Props {
  children?: React.ReactNode
}

const Base = observer((props: Props) => {
  const [lang, setLang] = useState('sk')
  const [catalogs, setCatalogs] = useState({ sk } as any)
  const unsupportedBrowserDialog = useDialog()
  const { currentRole } = appStore
  const currentBrowserSupportedVersion = Number(SupportedBroswerMap[browserName]) || null
  const [cacheInvalidateToken] = useState(() => randomString())

  // TODO: notify bugsnag on error
  const [loaded] = useScript(
    `https://${BASE_URLS.cms}/translations/translation_web_${lang}.js?${cacheInvalidateToken}`
  )
  const [cookieConsentLoaded] = useScript(`/static/cookieConsentDialog.min.js`)

  const isProductionHostname =
    process.browser &&
    process.env.NODE_ENV === 'production' &&
    (location.hostname === BASE_URLS.prod || location.hostname === BASE_URLS.prodV2)

  // const isUatHostname =
  //   process.browser &&
  //   process.env.NODE_ENV === 'production' &&
  //   location.hostname === BASE_URLS.uat

  const initTracking = () => {
    if (isProductionHostname) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY)

      Router.events.on('routeChangeComplete', (url: string) => {
        ReactGA.pageview(url)
      })
    }
  }

  // we are showing livechat on every page (for now)
  // const handleLiveChat = () => {
  //   // @ts-ignore
  //   if (window.livechatoo) {
  //     if (shouldShowLivechat()) {
  //       // @ts-ignore
  //       window.livechatoo.embed.init()
  //     } else {
  //       // @ts-ignore
  //       window.livechatoo.embed.stop()
  //     }
  //   }
  // }

  useEffect(() => {
    setTimeout(() => {
      if (
        currentBrowserSupportedVersion === null ||
        (currentBrowserSupportedVersion && Number(browserVersion) < currentBrowserSupportedVersion)
      ) {
        unsupportedBrowserDialog.open()
      }
    }, 1000 * 60 * 60)

    Router.events.on('routeChangeStart', () => NProgress.start())
    Router.events.on('routeChangeError', () => NProgress.done())
    Router.events.on('routeChangeComplete', () => {
      NProgress.done()
      // handleLiveChat()
    })
  }, [])

  useEffect(() => {
    if (loaded) {
      // this key `__trans_${lang}` is hardcoded in  CI
      const translations = (window as any)[`__trans_${lang}`]
      setCatalogs({ [lang]: translations })
    }
  }, [loaded, lang])

  useEffect(() => {
    if (
      currentBrowserSupportedVersion === null ||
      (currentBrowserSupportedVersion && Number(browserVersion) < currentBrowserSupportedVersion)
    ) {
      unsupportedBrowserDialog.open()
    }

    // @ts-ignore
    if (loaded && cookieConsentLoaded && window.CookieConsentDialog) {
      // @ts-ignore
      const cookieConsent = window.CookieConsentDialog({
        template: 'banner',
        position: 'bottom',
        cookies: [
          {
            id: 'analytical',
            label: '',
            description: '',
            required: false,
          },
        ],
        acceptAllButton: true,
        labels: {
          title: i18n.t`POUŽÍVANIE COOKIES`,
          description: i18n.t`Na našich webových stránkach používame cookies, ktoré sú nevyhnutné pre ich správne fungovanie, a ktoré slúžia na zlepšenie našich služieb a Vášho zážitku z čítania.Kliknutím na „OK“ vyjadríte svoj súhlas s používaním cookies a iných podobných technológií. Správu a odmietnutie cookies nastavíte priamo vo Vašom prehliadači. <a href="${LINKS.gdpr}" target="_blank" class="cookie-consent-button">Viac informácií</a> `,
          button: {
            default: i18n.t`OK`,
            // Shown when `acceptAllButton` is set, and no option is selected.
            acceptAll: i18n.t`OK`,
          },
        },
      })

      const acceptedCookies = cookieConsent.isAccepted('analytical')

      if (acceptedCookies) {
        initTracking()
      } else {
        // @ts-ignore
        cookieConsent.on('update', cookies => {
          // @ts-ignore
          const accepted = cookies.filter(cookie => cookie.accepted && cookie.id === 'analytical')

          if (accepted.length > 0) {
            initTracking()
          }
        })
      }
    }
  }, [loaded, cookieConsentLoaded])

  useEffect(() => {
    // FIXME: remove, instead persist through localStorage
    const sp = new URLSearchParams(window.location.search.replace('?', ''))
    const langInSearch = sp.get('lang') || 'sk'
    if (langInSearch !== lang) {
      setLang(langInSearch)
    }
  }, [lang])

  // useEffect(() => {
  //   if (canUseDOM) {
  //     if (process.env.NODE_ENV === 'production') {
  //       ;(window as any).livechatooCmd = () => {
  //         ;(window as any).livechatoo.embed.init({ account: 'chd', lang: 'sk', side: 'right' })
  //       }
  //       const l = document.createElement('script')
  //       l.type = 'text/javascript'
  //       l.async = !0
  //       l.src = `${document.location.protocol}//app.livechatoo.com/js/web.min.js`
  //       const s = document.getElementsByTagName('script')[0]
  //       if (s.parentNode) s.parentNode.insertBefore(l, s)
  //     }
  //   }
  // }, [])

  return (
    // <I18nProvider language={lang} catalogs={catalogs} i18n={i18n}>
    // not sending i18n props because of https://github.com/lingui/js-lingui/issues/437
    <I18nProvider i18n={i18n} language={lang} catalogs={catalogs}>
      <BusProvider bus={globalBus}>
        <Header userRole={currentRole} />
        <ErrorBoundary>{props.children}</ErrorBoundary>
        <Footer userRole={currentRole} />
        <Sprite2 />
        <Sprite />
        {unsupportedBrowserDialog.isOpen && (
          <ModalUnsupportedBrowser close={unsupportedBrowserDialog.close} />
        )}
      </BusProvider>
    </I18nProvider>
  )
})

export default Base
