// import { isDecimal, isFloat } from 'validator'
// import { FieldState } from 'formstate'

/**
 * checks whether rawValue matches empty string of decimal or flaot
 */
// function matchIntermediateDouble(rawValue: string) {
//   if (rawValue === '' || isDecimal(rawValue) || isFloat(rawValue)) {
//     return true
//   }

//   return false
// }

/**
 * cuts after second floating point number
 */
export function limitTwoFloatingPoints(rawValue: string) {
  return rawValue.replace(/^(?=.?\d)(\d*)(\.\d{0,2})(\d)*/gm, '$1$2')
}

/**
 * sanitize input and set if valid
 */
// export function sanitizeAndSetIntermediaryDouble(field: FieldState<string>, value: string) {
//   const rawValue = value // .replace(',', '.')

//   field.onChange(limitTwoFloatingPoints(rawValue))

//   // if (matchIntermediateDouble(rawValue)) {
//   //   field.onChange(limitTwoFloatingPoints(rawValue))
//   // }
// }

export function sanitizeNumberInput(
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) {
  if (/^-?\d*[.,]?\d*$/.test(e.currentTarget.value + e.key) === false) {
    e.preventDefault()
    e.stopPropagation()
  }

  if (e.key === ',' && !['sk-SK', 'sk', 'cs-CZ', 'cz'].includes(navigator.language)) {
    // disallow for non sk/cz locales due to dumb number parsing in firefox
    e.preventDefault()
    e.stopPropagation()
  }
}
