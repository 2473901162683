import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { Section, Container } from '../../ui-kit'
import { ShowinTheMiddle } from '../../utils/ShowInTheMiddle'

interface ErrorProps {
  title?: string
  buttonText?: string
  buttonAction?: () => {}
}

export const Error: FC<ErrorProps> = ({ title }) => {
  return (
    <main id="main">
      <Section color="light-grey" size="xxlarge">
        <Container>
          <ShowinTheMiddle>
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '2rem' }}>
              {title || <Trans>Nastala neočakávaná chyba</Trans>}
            </div>
          </ShowinTheMiddle>
        </Container>
      </Section>
    </main>
  )
}
