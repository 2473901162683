import { UserMessage } from '@odiupsk/up-api-client'
import { observable, computed, runInAction } from 'mobx'
import { upAxios } from '../../../../config'
import { appStore } from '../../../AppStore'
import { PaginationStore } from './PaginationStore'

export class MessageStore {
  @observable.ref messages: UserMessage[] = []

  @observable.ref loading: boolean = false

  @observable.ref numberOfUnread = 0

  pagination = new PaginationStore({ perPage: 30 })

  async fetchMessages(params: this['params']) {
    if (!appStore.currentUser) return

    runInAction(() => {
      this.loading = true
    })

    const response = await upAxios.get(
      `/users/${appStore.currentUser.id}/messages?sort=published_at&desc=published_at&page=${params.page}&per_page=${params.per_page}`
    )

    const numberOfUnreadResponse = await upAxios.get(`/messages/acknowledgment`)
    const data = response.data

    runInAction(() => {
      this.messages = [...this.messages, ...((data.data as any) as UserMessage[])]
      this.pagination.setFromPagination(data.pagination)
      this.loading = false
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  async refreshMessages(withData = true) {
    if (!appStore.currentUser) return

    if (withData) {
      const response = await upAxios.get(
        `/users/${
          appStore.currentUser.id
        }/messages?sort=published_at&desc=published_at&page=1&per_page=${this.pagination.perPage *
          this.pagination.page}`
      )
      const data = response.data

      runInAction(() => {
        this.messages = (data.data as any) as UserMessage[]
      })
    }

    const numberOfUnreadResponse = await upAxios.get(`/messages/acknowledgment`)

    runInAction(() => {
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  @computed get params() {
    return { ...this.pagination.params }
  }
}
