import React from 'react'
import { observable, action } from 'mobx'
import { Employee } from '@odiupsk/up-api-client'
import { employeesApi } from '../common/api'

const EMPLOYEE_ID_LOCAL_STORAGE_KEY = 'context.employeeId'

export class EmployeeStore {
  @observable.ref employee?: Employee = undefined

  @observable isLoading = false

  @action async fetchEmployee(employeeId: string, force = false) {
    if (this.employee && employeeId === this.employee.id && !force) return

    this.isLoading = true
    const response = await employeesApi.getEmployee(employeeId)
    this.saveEmployeeIdToLocalStorage(employeeId)
    this.employee = response.data
    this.isLoading = false
  }

  saveEmployeeIdToLocalStorage = (employeeId: string) => {
    localStorage.setItem(EMPLOYEE_ID_LOCAL_STORAGE_KEY, employeeId)
  }

  getEmployeeIdFromLocalStorage = () => {
    return localStorage.getItem(EMPLOYEE_ID_LOCAL_STORAGE_KEY)
  }
}

export const employeeStore = new EmployeeStore()

/**
 * Use only after employee was fetched & set on EmployeeCtx.Provider !!!
 */
export const EmployeeCtx = React.createContext<Employee>(employeeStore.employee!)
