import React from 'react'
import { observer } from 'mobx-react-lite'
import { Trans } from '@lingui/macro'
import { Card, CardContent, Container, GridCol, Overlay, Section } from '../common/ui-kit'

export const MaintenancePageContent = observer(function PageMaintenance() {
  return (
    <main id="main" className="maintenance">
      <Overlay
        bgSrc="/static/img/signup-background.png"
        color="transparent"
        className="bg-turquoise-500">
        <Container>
          <Section size="xxlarge" style={{ marginBottom: '100px', marginTop: '100px' }}>
            <GridCol size={{ s: 12, m: 10, l: 8 }}>
              <Card>
                <CardContent>
                  <h1 className="h2 mb-large">
                    <Trans>Plánovaná odstávka</Trans>
                  </h1>
                  <p className="mb-smallish">
                    Ospravedlňujeme sa Vám za nedostupnosť zákazníckej zóny Up. V čase od 17:00 do
                    19:00 hod. prebieha plánovaná odstávka.
                  </p>
                </CardContent>
              </Card>
            </GridCol>
          </Section>
        </Container>
      </Overlay>
    </main>
  )
})
