import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Trans } from '@lingui/macro'
import { i18n } from '@lingui/core'
import {
  ReachDialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  ButtonLayout,
  Button,
} from '../../ui-kit'

export interface ModalUnsupportedBrowserProps {
  close: () => void
}
export const ModalUnsupportedBrowserID = 'dialog-unsupported-browser'

export const ModalUnsupportedBrowser = observer(({ close }: ModalUnsupportedBrowserProps) => {
  useEffect(() => {
    if (window.location.pathname === '/supported-browsers') {
      close()
    }
  }, [])

  return (
    <ReachDialog id={ModalUnsupportedBrowserID} onDismiss={close}>
      <DialogHeader title={i18n.t`Upozornenie`} />
      <DialogContent>
        <p>
          <Trans>Webová stránka nie je vo Vašom prehlliadači plne podporovaná. </Trans>
          {` `}
          <a href="/supported-browsers" style={{ textDecoration: 'underline' }}>
            <Trans>Zoznam podporovaných prehliadačov</Trans>
          </a>
        </p>
      </DialogContent>
      <DialogFooter>
        <ButtonLayout direction="horizontal" className="align-items-right">
          <Button onClick={close}>
            <Trans>Zatvoriť</Trans>
          </Button>
        </ButtonLayout>
      </DialogFooter>
    </ReachDialog>
  )
})
