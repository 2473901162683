/* eslint-disable class-methods-use-this */
import { Notice } from '@odiupsk/up-api-client'
import { observable, computed, runInAction } from 'mobx'
import { upAxios } from '../../../../config'
import { appStore } from '../../../AppStore'
import { PaginationStore } from './PaginationStore'

export class NotificationStore {
  @observable.ref notifications: Notice[] = []

  @observable.ref loading: boolean = false

  @observable.ref numberOfUnread = 0

  pagination = new PaginationStore({ perPage: 30 })

  async fetchNotices(params: this['params']) {
    if (!appStore.currentUser) return

    runInAction(() => {
      this.loading = true
    })

    const response = await upAxios.get(
      `/users/${appStore.currentUser.id}/notices?sort=published_at&desc=published_at&page=${params.page}&per_page=${params.per_page}`
    )

    const numberOfUnreadResponse = await upAxios.get(`/notices/acknowledgment`)
    const data = response.data

    runInAction(() => {
      this.notifications = [...this.notifications, ...((data.data as any) as Notice[])]
      this.pagination.setFromPagination(data.pagination)
      this.loading = false
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  async refreshNotices(withData = true) {
    if (!appStore.currentUser) return

    if (withData) {
      const response = await upAxios.get(
        `/users/${
          appStore.currentUser.id
        }/notices?sort=published_at&desc=published_at&page=1&per_page=${this.pagination.perPage *
          this.pagination.page}`
      )
      const data = response.data

      runInAction(() => {
        this.notifications = (data.data as any) as Notice[]
      })
    }

    const numberOfUnreadResponse = await upAxios.get(`/notices/acknowledgment`)

    runInAction(() => {
      this.numberOfUnread = numberOfUnreadResponse.data
    })
  }

  async acknowledgeAll() {
    if (!appStore.currentUser) return

    await upAxios.post(`/notices/acknowledgment/all`)
    await this.refreshNotices(true)
  }

  @computed get params() {
    return { ...this.pagination.params }
  }
}
