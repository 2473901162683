import React, { useEffect, useRef, useState } from 'react'
import Router from 'next/router'

import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { clientStore } from '../../../../client/ClientStore'
import { Icon } from '../../../ui-kit'
import { PRODUCT, PRODUCTS, ProductType } from '../../../../config/constants'

const ArrowWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`

const Arrow = styled.div<{ reverted?: boolean }>`
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border-radius: 3px;
  margin-left: 10px;
  ${props =>
    props.reverted &&
    `
  transform: rotate(180deg)
  `}
`

const Wrapper = styled.div`
  position: relative;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    & ${Arrow} {
      background: rgba(255, 255, 255, 0.25);
    }
  }
`

const Placeholder = styled.div`
  position: relative;
`

const Item = styled.div`
  display: flex;
  height: 100%;
`

const IconWrapper = styled.div`
  height: 44px;
  width: 44px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`

const Column = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Description = styled.div<{ color?: string }>`
  font-weight: 400;
  font-size: 14px;
  color: ${props => (props.color ? props.color : 'white')};
`

const Title = styled.div<{ color?: string }>`
  font-weight: 700;
  font-size: 16px;
  color: ${props => (props.color ? props.color : 'white')};
`

const List = styled.ul`
  left: 0;
  right: 0;
  top: calc(100% + 11px);
  background: white;
  position: absolute;
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  z-index: 10;
  &:after {
    content: '';
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
`

const ListItem = styled.li`
  height: 68px;
  padding: 0 15px;
  &:hover {
    background: #fbe8d4;
  }
`

const Radio = styled.div<{ active?: boolean }>`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 1px solid #48544d;
  background: white;
  ${props =>
    props.active &&
    `
  border: 2px solid #F59100;
  `}
  &:after {
    content: '';
    display: ${props => (props.active ? 'block' : 'none')};
    height: 10px;
    width: 10px;
    background: #f59100;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 100%;
  }
`

const ProductChangeDropdown: React.FC = observer(() => {
  const [open, setOpen] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (listRef.current && !listRef.current.contains(event.target as Node)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.addEventListener('mousedown', handleClickOutside)
  }, [listRef])

  const handleChange = (product: PRODUCT) => {
    clientStore.activeProduct = product
    setOpen(false)
    Router.push({
      pathname: '/client',
      query: { product: PRODUCTS[product]?.id },
    })
  }

  const getProductInfo: () => ProductType | undefined = () => {
    if (clientStore.activeProduct) {
      return PRODUCTS[clientStore.activeProduct]
    }
    return undefined
  }

  const renderProductItem = (options: { product: PRODUCT; title: string; subtitle: string }) => {
    return (
      <ListItem onClick={() => handleChange(options.product)}>
        <Item>
          <Column>
            <Description color="#48544D">
              <Trans>{options.subtitle}</Trans>
            </Description>
            <Title color="#48544D">
              <Trans>{options.title}</Trans>
            </Title>
          </Column>
          <Column style={{ marginLeft: 'auto' }}>
            <Radio active={clientStore.activeProduct === options.product} />
          </Column>
        </Item>
      </ListItem>
    )
  }

  return (
    <Wrapper ref={listRef}>
      <Placeholder onClick={() => setOpen(!open)}>
        <Item>
          <Column>
            <IconWrapper>
              <Icon name={getProductInfo()?.iconCode} size="medium" />{' '}
            </IconWrapper>
          </Column>
          <Column>
            <Description>{getProductInfo()?.description}</Description>
            <Title>{getProductInfo()?.name}</Title>
          </Column>
          <Column>
            <ArrowWrapper>
              <Arrow reverted={open}>
                <Icon name="chevron-down" size="medium" />
              </Arrow>
            </ArrowWrapper>
          </Column>
        </Item>
      </Placeholder>

      {open && (
        <List>
          {clientStore.getManageableProducts()?.map(manageableProduct => {
            if (manageableProduct) {
              const p = Object.values(PRODUCTS).find(
                product => product.id === manageableProduct?.product_id
              )
              if (p) {
                return renderProductItem({
                  product: p.code,
                  title: p.name,
                  subtitle: p.description,
                })
              }
            }
            return null
          })}
        </List>
      )}
    </Wrapper>
  )
})

export default ProductChangeDropdown
