/* eslint-disable */

import { canUseDOM } from './hoc'

function closestAndMatches() {
  if (!canUseDOM) return
  const ElementPrototype = window.Element.prototype

  if (typeof ElementPrototype.matches !== 'function') {
    ElementPrototype.matches =
      ElementPrototype.msMatchesSelector ||
      ElementPrototype.mozMatchesSelector ||
      ElementPrototype.webkitMatchesSelector ||
      function matches(selector) {
        const element = this
        const elements = (element.document || element.ownerDocument).querySelectorAll(selector)
        let index = 0

        while (elements[index] && elements[index] !== element) {
          ++index
        }

        return Boolean(elements[index])
      }
  }

  if (typeof ElementPrototype.closest !== 'function') {
    ElementPrototype.closest = function closest(selector) {
      let element = this

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element
        }

        element = element.parentNode
      }

      return null
    }
  }
}

closestAndMatches()

// Polyfill for creating CustomEvents on IE9/10/11

// code pulled from:
// https://github.com/d4tocchini/customevent-polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent#Polyfill
;(function() {
  if (typeof window === 'undefined') {
    return
  }

  try {
    var ce = new window.CustomEvent('test', { cancelable: true })
    ce.preventDefault()
    if (ce.defaultPrevented !== true) {
      // IE has problems with .preventDefault() on custom events
      // http://stackoverflow.com/questions/23349191
      throw new Error('Could not prevent default')
    }
  } catch (e) {
    var CustomEvent = function(event, params) {
      var evt, origPrevent
      params = params || {}
      params.bubbles = !!params.bubbles
      params.cancelable = !!params.cancelable

      evt = document.createEvent('CustomEvent')
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
      origPrevent = evt.preventDefault
      evt.preventDefault = function() {
        origPrevent.call(this)
        try {
          Object.defineProperty(this, 'defaultPrevented', {
            get: function() {
              return true
            },
          })
        } catch (e) {
          this.defaultPrevented = true
        }
      }
      return evt
    }

    CustomEvent.prototype = window.Event.prototype
    window.CustomEvent = CustomEvent // expose definition to window
  }
})()
