import { FieldState } from 'formstate'
import { sanitizeNumberInput } from '../forms/transformInput'

type Message = {
  text: string
  type: 'error'
}

interface FieldToInputProps {
  onChange: any
  onKeyPress?: any
  value: string
  messages?: Message[]
}

// TODO: merge messages
export const fieldToInputProps = (
  field: FieldState<any>,
  sanitize: (val: any) => any = val => val
): FieldToInputProps => {
  const props: FieldToInputProps = {
    value: field.value,
    onChange: (e: React.FormEvent<HTMLInputElement>) =>
      field.onChange(sanitize(e.currentTarget.value)),
  }

  if (field.hasError) {
    props.messages = [
      {
        type: 'error',
        text: field.error || '',
      },
    ]
  }

  return props
}

export const fieldToNumberInputProps = (
  field: FieldState<any>,
  sanitize: (val: any) => any = val => val
): FieldToInputProps => {
  const props: FieldToInputProps = {
    value: field.value,
    onChange: (e: React.FormEvent<HTMLInputElement>) =>
      field.onChange(sanitize(e.currentTarget.value)),
    onKeyPress: (e: any) => {
      // debugger
      sanitizeNumberInput(e)
    },
  }

  if (field.hasError) {
    props.messages = [
      {
        type: 'error',
        text: field.error || '',
      },
    ]
  }

  return props
}
