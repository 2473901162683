import { isPostalCode, isEmail, isMobilePhone, isNumeric, isDecimal } from 'validator'
import { FormState, ValidatableMapOrArray, ComposibleValidatable } from 'formstate'
import { t, plural } from '@lingui/macro'
import { isInteger } from 'lodash-es'
import { _, i18n } from '../intl'

export function required($: string | number | undefined) {
  return $ == null || $ === '' || !String($).trim() ? _(t`Povinné pole`) : null
}

export function login($: string) {
  if ($ === '' || $ == null) return

  return (
    validEmailOrUpSlovensko($) &&
    mobilePhone($) &&
    (length(16)($) || onlyNumericWithoutDecimal($)) &&
    i18n.t`Musí byť validný email, tel. čislo vo formáte +421 123 456 789 alebo 16 miestne čislo karty`
  )
}

export function postalCode($: string) {
  if ($ === '' || $ == null) return
  return isPostalCode($, 'CZ') ? null : _(t`Musí byť vo fomáte 123 45`)
}

export function email($: string) {
  if ($ === '' || $ == null) return
  return isEmail($) ? null : _(t`Nevalidný email`)
}

export function validEmailOrUpSlovensko($: string) {
  if ($ === '' || $ == null) return

  const idx = $.lastIndexOf('@')
  if (idx > -1 && $.slice(idx + 1) === 'up-slovensko.sk') {
    return null
  }

  return isEmail($) ? null : _(t`Nevalidný email`)
}

export function mobilePhone($: string) {
  if ($ === '' || $ == null) return
  return isMobilePhone($, 'sk-SK', { strictMode: true })
    ? null
    : _(t`Číslo zadajte v správnom formáte s predvoľbou, napr.: +421903123456`)
}

export function onlyNumeric($: string) {
  if ($ === '' || $ == null) return
  return isNumeric($) ? null : _(t`Môže obsahovať len číslice`)
}

export function onlyNumericWithoutDecimal($: string) {
  if ($ === '' || $ == null) return
  return isNumeric($, { no_symbols: true }) ? null : _(t`Môže obsahovať len celé čísla`)
}

export function minValue(minVal: number, format = (_minVal: number) => _minVal.toString()) {
  return ($: string | number) => {
    if ($ === '' || $ == null) return
    return Number($) >= minVal ? null : _(t`Minimálna hodnota je ${format(minVal)}`)
  }
}

export function maxValue(maxVal: number, format = (_maxVal: number) => _maxVal.toString()) {
  return ($: string | number) => {
    if ($ === '' || $ == null) return
    return Number($) <= maxVal ? null : _(t`Maximálna hodnota je ${format(maxVal)}`)
  }
}

export function cantBeZero($: string) {
  if ($ === '' || $ == null) return
  return parseFloat($) !== 0 || !isNumeric($) ? null : _(t`Nemôže byť nula`)
}

export function length(len: number) {
  return ($: string) => {
    if ($ === '' || $ == null) return
    return $.length !== len ? _(t`Reťazec musí obsahovať ${len} znakov`) : null
  }
}

export function maxLength(len: number) {
  return ($: string) => {
    if ($ === '' || $ == null) return
    return $.length > len ? _(t`Reťazec musí obsahovať maximálne ${len} znakov`) : null
  }
}

export function minLength(len: number) {
  return ($: string) => {
    if ($ === '' || $ == null) return
    return $.length < len
      ? i18n._(
          plural({
            value: len,
            one: `Reťazec musí obsahovať minimálne 1 znak`,
            few: `Reťazec musí obsahovať minimálne # znaky`,
            many: `Reťazec musí obsahovať minimálne # znakov`,
            other: `Reťazec musí obsahovať minimálne # znakov`,
          })
        )
      : null
  }
}

// Password must be at least 8 characters long, must contain at least 1 capital letter, 1 lowercase letter and 1 digit
export function validPassword($: string) {
  if ($ === '' || $ == null) return
  return new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test($)
    ? null
    : _(t`Heslo musí obsahovať 8 znakov, aspoň 1 veľké písmeno, 1 malé písmeno a 1 číslo`)
}

export function validVoucherCount($: string) {
  if ($ === '' || $ == null) return
  return isNumeric($, { no_symbols: true }) && Number($) >= 1 ? null : _(t`Nesprávny počet`)
}

export function validMoney($: string) {
  if ($ === '' || $ == null) return
  return isDecimal($, { decimal_digits: '0,2' }) ? null : _(t`Nesprávna suma`)
}

export function integer($: number) {
  if ($ == null) return
  return isInteger($) ? null : _(t`Nesprávna suma`)
}

export function isFormStateDirty<T extends ValidatableMapOrArray>(formState: FormState<T>) {
  const mode = (formState as any).mode as 'array' | 'map' | 'object'

  if (mode === 'array') {
    const f = formState.$ as ComposibleValidatable<T>[]
    f.forEach(val => console.log(val))
  }

  if (mode === 'map') {
    const f = formState.$ as Map<any, ComposibleValidatable<T>>
    f.forEach(val => console.log(val))
  }

  if (mode === 'object') {
    const f = formState.$ as { [key: string]: ComposibleValidatable<T> }
    Object.entries(f).forEach(([val]) => console.log(val))
  }
  // return Object.entries(formState).some(([name, field]) => {
  //   if (field instanceof FieldState) {
  //     return field.dirty || false
  //   }
  //   if (field instanceof FormState) {
  //     return isFormStateDirty(field.$)
  //   }

  //   return false
  // })
}

export function isICO() {
  return [required, onlyNumeric, length(8), testICO]
}

export function isDIC() {
  return [required, onlyNumeric, length(10)]
}

function testICO(x: string) {
  try {
    let a = 0
    if (x.length !== 8) throw new Error()
    const b = x.split('')
    let c = 0
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 7; i++) a += parseInt(b[i], 10) * (8 - i)
    a %= 11
    c = 11 - a
    if (a === 1) c = 0
    if (a === 0) c = 1
    if (a === 10) c = 1
    if (parseInt(b[7], 10) !== c) throw new Error()
  } catch (e) {
    return _(t`Nevalidný formát IČO`)
  }
  return undefined
}

const EMBOSSNAME_REGEX = /^[\p{L}0-9 +.*/-]{1,40}$/gu
const INVALID_CHARACTERS_REGEX = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\p{L}0-9 \x20-\x7E]*$/

export function embossname($: string) {
  if ($.length && !$.match(EMBOSSNAME_REGEX)) return i18n.t`Nevalidný formát`
}

export function onlyASCII($: string) {
  if ($ === '' || $ == null) return
  if (!$.match(INVALID_CHARACTERS_REGEX)) return i18n.t`Obsahuje nepovolené znaky`
}

export function noWhiteSpaces($: string) {
  if ($ === '' || $ == null) return $
  return $.replace(/\s/g, '')
}
