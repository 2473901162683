import React from 'react'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'core-js/proposals/url'
import '../src/common/utils/polyfills'
import App from 'next/app'
import bugsnagReact from '@bugsnag/plugin-react'
import Base from '../src/common/modules/layout/Base'
import { Alerts } from '../src/common/ui-kit/alert/Alert'
import { bugsnagClient } from '../src/config'
import { MAINTENANCE_MODE } from '../src/config/constants'
import { MaintenancePageContent } from '../src/maintenance/MaintenancePageContent'

bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

class UpApp extends App {
  state = {
    lang: 'en',
    showLoginModal: false,
  }

  static getInitialProps = async ({ Component, ctx }: any) => {
    // console.log(router)
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return { pageProps }
  }

  // componentDidMount() {
  // if (!isProductionHostname && !isUatHostname) {
  // eslint-disable-next-line
  // new MobxConsoleLogger(mobx, { useGroups: true })
  // }
  // }

  render() {
    const { Component, pageProps } = this.props

    return (
      <ErrorBoundary>
        <Base>
          {!MAINTENANCE_MODE ? (
            <>
              <Component {...pageProps} />
              <Alerts />
            </>
          ) : (
            <MaintenancePageContent />
          )}
        </Base>
      </ErrorBoundary>
    )
  }
}

export default UpApp
